<template>
  <div class="s_task_undone_con_rec_pop">
    <van-popup v-model="show" position="right" :safe-area-inset-bottom="true" :style="{ width: '100%', height: '100%' }" class="s-task-undone-con-rec-container g-flex-column">
      <head-back :isRouterBack="false" @emitHeadBack="emitHeadBack">
        <template v-slot:returnText>
          <span>返回</span>
        </template>
        <template>
          <span>{{title}}</span>
        </template>
      </head-back>
      <div class="s-task-undown-con-rec-content">
        <div class="s-task-undown-con-rec-top" v-if="taskType == 11 || taskType == 12">
          <p class="s-task-undown-con-rec-top-title">(请根据以下指定内容填写好评)</p>
          <div class="s-task-undown-con-rec-top-size g-flex-align-center" v-if="taskType == 12">
            <p class="s-task-undown-con-rec-top-size-title">尺码:</p>
            <span class="s-task-undown-con-rec-top-size-value">{{formData.size}}</span>
          </div>
          <div class="s-task-undown-con-rec-top-img" v-if="taskType == 12">
            <p class="s-task-undown-con-rec-top-size-title">晒图（请保存该图片进行好评）:</p>
            <!-- <p class="s-task-undown-con-rec-top-size-title g-fsize16 g-font-red" v-if="isApp == 1">(为保证真实性,请复制图片地址到浏览器打开，下载保存至本地相册)</p> -->
            <div class="s-task-undown-con-rec-top-img-list g-flex-align-center g-flex-wrap">
              <div class="s-task-undown-con-rec-top-img-item g-flex-1" v-for="(item, index) in formData.img" :key="index">
                <van-image width="100" fit="cover" height="100" :src="item" @click="previewImg(item)"/>
                <van-button type="info" size="small" @click="createDownload(item)" v-if="isApp == 1">下载</van-button>
                <van-button type="info" size="small" @click="downLoadImage(item)" v-else>下载</van-button>
              </div>
            </div>
          </div>

          <div class="s-task-undown-con-rec-top-review" v-if="taskType == 11 || taskType == 12">
            <p class="s-task-undown-con-rec-top-size-title">指定评价内容(请复制评价内容进行好评):</p>
            <span class="s-task-undown-con-rec-top-size-value">
              {{formData.reviews}}
            </span>
            <van-button type="primary" @click="copyClick(formData.reviews)">复制</van-button>
          </div>
        </div>
        <!-- 上传 -->
        <div class="s-task-details-bottom-upload g-flex-column">
          <p class="s-task-details-bottom-upload-title">上传截图:</p>
          <div class="s-task-details-bottom-upload-list g-flex-align-center">
            <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
              <task-details-bottom-upload :imgUrl.sync="form.receive_goods_img" />
              <span class="s-task-details-bottom-upload-list-item-title">确认收货截图</span>
            </div>
            <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
              <task-details-bottom-upload :imgUrl.sync="form.comment_img" />
              <span class="s-task-details-bottom-upload-list-item-title">评价截图</span>
            </div>
          </div>
        </div>

        <div class="s-task-undone-con-rec-submit g-flex-align-center">
          <div class="s-task-undone-con-rec-submit-cancel">
            <operation-button :buttonObj="buttonObjSecond" @clickItem="emitClickItem"/>
          </div>
          <div class="s-task-undone-con-rec-submit-yes">
            <operation-button :buttonObj="buttonObj" @clickItem="emitClickItem"/>
          </div>
          
        </div>
      </div>

    </van-popup>
  </div>
</template>

<script>
import TaskDetailsBottomUpload from '@/components/TaskDetailsBottomUpload.vue'
import OperationButton from '@/components/OperationButton.vue'
import { apiConfirmReceipt } from '@/utils/api.js'
import HeadBack from '@/components/HeadBack.vue'
import { ImagePreview } from 'vant';
export default {
  components: { TaskDetailsBottomUpload, OperationButton, HeadBack },
  data() {
    return {
      show: false,
      title: '确认收货截图',
      taskType: 0, //10 普通好评 11指定文字好评 12图片好评
      formData: {
        img: [],
        keyword: "",
        num: "",
        reviews: "",
        size: ""
      },
      form: {
        id: '',
        receive_goods_img: '', //收货截图
        comment_img: '' // 评价截图
      },
      buttonObj: {
        claSS: 'c-btn-default ',
        title: '确认提交',
        event: 'apiConfirmReceiptHandel'
      },
      buttonObjSecond: {
        claSS: 'c-btn-cancel c-btn-text-color-white',
        title: '取消',
        event: 'onClose'
      }
    }
  },
  created() {
    if (window.plus) {
      this.plusReady()
    } else {
      document.addEventListener('plusready',this.plusReady, false) 
    }
  },
  methods: {
    plusReady () {
      var ws = window.plus.webview.currentWebview(); //pw回车可输出plus.webview  
      console.log(ws)
    },
    // 点击图片预览
    previewImg(item) {
      ImagePreview([item])
    },
    // 点击复制
    copyClick(message) {
      this.$copyText(message).then(() => {
        this.$toast({
          type: 'success',
          message: '复制成功'
        });
      },  () => {
        this.$toast('复制失败,请重试');
      })
    },
    emitHeadBack() {
      this.onClose()
    },
    emitClickItem(event) {
      if(!event) return
      this[event]()
    },
    onShow(obj) {
      this.formData = obj.kw_data
      this.taskType = obj.task_type.id
      this.form.id = obj.id
      this.show = true
    },

    // 下载
    createDownload(item) {
      if(!window.plus) return this.$toast('图片异常，请联系客服')
      var url = decodeURI(item)
      // 新建一个下载对象 (返回值是一个下载对象)
      var dtask = window.plus.downloader.createDownload(url, {}, (d, status) => {
        // 下载完成
        if(status == 200) {
          // 保存到相册方法
          window.plus.gallery.save(d.filename, () => { 
            this.$toast('下载成功,已经保存到手机相册')
          }, () => {
            this.$toast('保存失败，请重试！')
          })
        } else {
          this.$toast('下载失败，请重试！')
        }  
      })

      // 开始下载
      dtask.start() 
    },

    // 下载图片
    downLoadImage(url) {
      let a = document.createElement("a");
      a.style.display = "none";
      a.download = 'xxxx';
      let api = process.env.NODE_ENV == 'development' ? '/api' : ''
      a.href = `${api}/home/main/downImg?file=${encodeURI(url)}`
      document.body.appendChild(a);
      a.click()
    },

    // 复制图片地址
    copyImgUrl(url) {
      this.$copyText(url).then(() => {
        this.$toast({
          type: 'success',
          message: '复制成功'
        });
      },  () => {
        this.$toast('复制失败,请重试');
      })
    },

    // getUrlBase64(url) {
    //   return new Promise(resolve => {
    //     let canvas = document.createElement("canvas");
    //     let ctx = canvas.getContext("2d");
    //     let img = new Image();
    //     img.crossOrigin = "Anonymous"; //允许跨域
    //     img.src = url;
    //     img.onload = () => {
    //       canvas.height = 300;
    //       canvas.width = 300;
    //       ctx.drawImage(img, 0, 0, 300, 300);
    //       let dataURL = canvas.toDataURL("image/png");
    //       canvas = null;
    //       resolve(dataURL);
    //     };
    //   });
    // },

    onClose() {
      this.form.receive_goods_img = ''
      this.form.comment_img = ''
      this.show = false 
    },
    // 确认收货
    async apiConfirmReceiptHandel() {
      if(!this.form.receive_goods_img) return this.$toast('收货截图不能为空')
      if(!this.form.comment_img) return this.$toast('评价截图不能为空')
      this.$global.loadingShow = true
      const { success, data } = await apiConfirmReceipt(this.form)
      if(!success) return
      this.$toast(data.msg)
      console.log(data)
      this.$emit('emitConfirmReceive')
      this.onClose()
    }
  },
  computed: {
    // 是否是APP
    isApp() {
      return this.$global.isApp
    },

    userAgent() {
      let u = navigator.userAgent;
      let isMobile = !!u.match(/AppleWebKit.*Mobile.*/) //是否为移动端
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (!isMobile) return 'pc' // 是电脑
      else if (isiOS) return 'ios' //是ios
      else if(isAndroid) return 'andriod' //是安卓
      return 'other' //其他
  },
}
}
</script>

<style lang='scss'>
.s_task_undone_con_rec_pop {
  .van-overlay {
    background: #FFFFFF;
  }
  .s-task-undone-con-rec-container {
    .s-task-undown-con-rec-content {
      flex: 1;
      padding-bottom: 20px;
      overflow-y: auto;
      .s-task-undown-con-rec-top {
        padding: 0 20px 20px 20px;
        .s-task-undown-con-rec-top-title {
          padding: 10px 0;
          font-size: 16px;
          color: $main_color;
          font-weight: bold;
        }
        .s-task-undown-con-rec-top-size, .s-task-undown-con-rec-top-img, .s-task-undown-con-rec-top-review {
          .s-task-undown-con-rec-top-size-title {
            font-size: 14px;
            font-weight: bold;
            padding: 15px 0;
          }
          .s-task-undown-con-rec-top-size-value {
            font-size: 16px;
            flex: 1;
            padding-left: 6px;
            padding-right: 6px;
          }
        }
      }
      .s-task-details-bottom-upload {
        padding: 0 10px 0px 10px;
        .s-task-details-bottom-upload-title {
          padding: 10px 0 30px 0;
          font-size: 16px;
          color: #323233;
          font-weight: bold;
        }
        .s-task-details-bottom-upload-list {
          .s-task-details-bottom-upload-list-item {
            .s-task-details-bottom-upload-list-item-title {
              color: $grey;
              font-size: 16px;
              padding-top: 10px;
            }
          }
        }
      }
      .s-task-undone-con-rec-submit {
        margin-top: 30px;
        .s-task-undone-con-rec-submit-cancel {
          flex: 2;
        }
        .s-task-undone-con-rec-submit-yes {
          flex: 3;
        }
      }
    }

  }
}

</style>