<template>
  <div class="c_task_confirm_pop">
    <van-popup v-model="show" class="c-task-confirm-pop-container" style="width: 80%">
      <div class="c-task-cancel-close" @click="onCancel">
       <i class="iconfont icon-guanbi"></i> 
      </div>
      <div class="c-task-confirm-top">
        <p class="c-task-confirm-title">{{title}}</p>
      </div>
      <div class="c-task-confirm-middle">
        <p class="c-task-confirm-tips">{{tips}}</p>
      </div>

      <div class="c-task-confirm-bottom">
        <ul class="c-task-confirm-btn-list g-flex-align-center">
          <li class="c-task-confirm-btn-item g-flex-justify-center g-flex-align-center" v-for="(item, index) in btnList" :key="index" @click="itemClick(item)">
            <span>{{item.name}}</span>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default() {
          return '接受任务'
        }
      },
      tips: {
        type: String,
        defult() {
          return '确认接受此任务？'
        }
      },
      // btnList : { name: '关闭', event: 'comfirmPopClose' }
      btnList: {
        type: Array,
        default() {
          return []
        }
      }
    },
    data() {
      return {
        show: false
      }
    },
    methods: {
      onShow(flag) {
        this.show = flag
      },
      onCancel() {
        this.show = false
      },
      itemClick(item) {
        this.$emit('emitConfirmItemClick', item.event)
        this.show = false
      }
    }
  }
</script>

<style lang="scss">
.c_task_confirm_pop {
  .c-task-confirm-pop-container {
    border-radius: 8px;

    .c-task-cancel-close {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      .iconfont {
        font-size: 20px;
        color: #828282;
      }
    }

    .c-task-confirm-top {
      font-size: 16px;
      color: #000000;
      text-align: center;
      padding-top: 15px;
    }

    .c-task-confirm-middle {
      font-size: 16px;
      padding: 20px 26px 10px 26px;
    }

    .c-task-confirm-bottom {
      padding: 15px 16px 15px 16px;
      .c-task-confirm-btn-list {
        background: #FA436A;
        border-radius: 20px;
        .c-task-confirm-btn-item {
          flex: 1;
          color: $white;
          font-size: 16px;
          padding: 10px 0;
          span {
            flex: 1;
            text-align: center;
          }
          &:nth-of-type(1) {
            span {
              border-right: 1px solid $white;
            }
          }
          &:nth-last-of-type(1) {
            span {
              border-left: 1px solid $white;
            }
          }
        }
      }
    }

  }
}
</style>