<template>
  <div class="c_task_cancel">
    <van-popup v-model="show" position="bottom" :style="{ height: 'auto' }" class="c-task-cancel-container">
      <div class="c-task-cancel-close" @click="onCancel">
       <i class="iconfont icon-guanbi"></i> 
      </div>
      <div class="c-task-cancel-top">
        <p class="c-task-cancel-title">取消订单</p>
      </div>
      <div class="c-task-cancel-bottom">
        <p class="c-task-cancel-bottom-tip">取消后无法获取本任务佣金</p>
        <p class="c-task-cancel-bottom-title">选择取消订单原因：</p>
        <van-radio-group v-model="radio" checked-color="#FA436A">
          <van-cell-group>
            <van-cell :title="item.title" clickable @click="radioItemClick(item)" v-for="(item, index) in cancelList" :key="index">
              <template #right-icon>
                <van-radio :name="item.name" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>

        <van-field v-show="radio === 5" v-model="title"  rows="2" autosize  label="原因" type="textarea"  maxlength="100" placeholder="请输入原因" show-word-limit/>
      </div>


      <div class="c-task-cancel-btn">
        <ul class="c-task-cancel-btn-list g-flex-align-center">
          <li class="c-task-cancel-btn-item g-flex-justify-center g-flex-align-center" @click="onCancel">
            <span>关闭</span>
          </li>
          <li class="c-task-cancel-btn-item g-flex-justify-center g-flex-align-center" @click="onConfirm">
            <span>确认取消</span>
          </li>
        </ul>

      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        message: '',
        radio: 0,
        title: '',
        show: false,
        cancelId: null,
        cancelList: [{ title: '找不到任务商品', name: 1}, { title: '找不到详情页验证关键词', name: 2}, { title: '我没时间做了', name: 3}, { title: '价格不对', name: 4 }, { title: '其它原因', name: 5 }]
      }
    },
    methods: {
      onCancel() {
        this.resetData()
      },
      onConfirm() {
        if(!this.title) return this.$toast('请填写取消订单原因')
        this.$emit('emitConfirmCancelOrder', this.title)
        this.resetData()
      },
      
      resetData() {
        this.show = false
        this.radio = 0
        this.title = ''
      },

      radioItemClick(item) {
        this.radio = item.name
        if(item.name === 5) return this.title = ''
        this.title = item.title
      },

      onShow(flag) {
        this.show = flag
      },
    },
  }
</script>

<style lang="scss">
.c_task_cancel {
  .c-task-cancel-container {
    padding-top: 20px;

    .c-task-cancel-close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      .iconfont {
        font-size: 20px;
        color: #828282;
      }
    }

    .c-task-cancel-top {
      font-size: 16px;
      color: #000000;
      text-align: center;
    }
    
    .c-task-cancel-bottom {
      padding-bottom: 30px;
      .van-cell__title {
        color: #323233;
      }
      .c-task-cancel-bottom-tip {
        margin: 16px;
        padding: 8px 12px;
        background: #EEEEEE;
        color: #323233;
        font-size: 13px;
      }
      .c-task-cancel-bottom-title {
        font-size: 16px;
        color: #878585;
        padding: 10px 16px;
      }
    }

    .c-task-cancel-btn {
      padding: 15px 16px 30px 16px;
      .c-task-cancel-btn-list {
        background: #FA436A;
        border-radius: 20px;
        .c-task-cancel-btn-item {
          flex: 1;
          color: $white;
          font-size: 16px;
          padding: 10px 0;
          span {
            flex: 1;
            text-align: center;
          }
          &:nth-of-type(1) {
            span {
              border-right: 1px solid $white;
            }
          }
          &:nth-last-of-type(1) {
            span {
              border-left: 1px solid $white;
            }
          }
        }
      }
    }

  }
}
</style>